import HttpClient from "@trustpayments/http-client";
import { Container } from "typedi";
import { ThreeDSecureFactory } from "@trustpayments/3ds-sdk-js";
import { environment } from "../../environments/environment";
import { ConfigProvider } from "../../shared/services/config-provider/ConfigProvider";
import { ConfigService } from "../../shared/services/config-service/ConfigService";
import { GooglePaySdkProvider } from "../integrations/google-pay/google-pay-sdk-provider/GooglePaySdkProvider";
import { IGooglePaySdkProvider } from "../integrations/google-pay/google-pay-sdk-provider/IGooglePaySdkProvider";
import { PreventNavigationPopup } from "../message-subscribers/PreventNavigationPopup";
import { PaymentResultSubmitterSubscriber } from "../common-frames/PaymentResultSubmitterSubscriber";
import "../../shared/dependency-injection/ServiceDefinitions";
import { ApplePayClientInitializer } from "../../integrations/apple-pay/client/ApplePayClientInitializer";
import { IApplePayNamespace } from "../../integrations/apple-pay/client/models/IApplePayNamespace";
import { OfficialApplePaySession } from "../../integrations/apple-pay/client/services/session/OfficialApplePaySession";
import { APMClientInitializer } from "../../integrations/apm/client/APMClientInitializer";
import { GooglePayClientInitializer } from "../integrations/google-pay/google-pay-client-initializer/GooglePayClientInitializer";
import { PayPalClientInitializer } from "../../integrations/paypal/client/pay-pal-client-initializer/PayPalClientInitializer";
import { VisaSrcProvider } from "../../integrations/click-to-pay/digital-terminal/src/visa/VisaSrcProvider";
import { SentryBreadcrumbsSender } from "../../application/core/services/sentry-breadcrumbs-sender/SentryBreadcrumbsSender";
import { TokenizedCardClientInitializer } from "../../integrations/tokenized-card/client/TokenizedCardClientInitializer";
// import { TisaSrcProvider } from "../../integrations/click-to-pay/digital-terminal/src/tisa/TisaSrcProvider";
import { MastercardSrcProvider } from "../../integrations/click-to-pay/digital-terminal/src/mastercard/MastercardSrcProvider";
import { IPayPalInjector } from "../../integrations/paypal/client/IPayPalInjector";
import { OfficialPayPalNamespaceInjector } from "../../integrations/paypal/client/OfficialPayPalNamespaceInjector";
import { MessageBusPayPalTokenSource } from "../../integrations/paypal/client/MessageBusPayPalTokenSource";

import { MockedPayPalNamespaceInjector } from "../../integrations/paypal/client/pay-pal-mocks/MockedPayPalNamespaceInjector";
import { MessageBusPayPalJwtListener } from "../../integrations/paypal/client/MessageBusPayPalJwtListener";
import { MessageBusPayPalTokenPublisher } from "../../integrations/paypal/client/MessageBusPayPalTokenPublisher";
import { OfficialApplePayButtonFactory } from "../../integrations/apple-pay/client/services/button/OfficialApplePayButtonFactory";
import { MockedApplePayNamespace } from "../../integrations/apple-pay/client/mock/MockedApplePayNamespace";
import { DefaultApplePayEventBinder } from "../../integrations/apple-pay/client/services/payment/DefaultApplePayEventBinder";
import {
  ApplePayApplePayEventBinderToken,
  ApplePayButtonFactoryToken,
  PayPalJwtListenerToken,
  PayPalTokenPublisherToken,
  PayPalTokenSourceToken,
} from "./InjectionTokens";
Container.set({
  id: ConfigProvider,
  factory: () => Container.get(ConfigService),
});
Container.set({ id: ThreeDSecureFactory, type: ThreeDSecureFactory });
Container.set({ id: IGooglePaySdkProvider, type: GooglePaySdkProvider });

/**
 * PayPal Dependencies Start Here
 */
Container.set({
  id: IPayPalInjector,
  type: environment.PAYPAL.USE_UI_MOCK
    ? MockedPayPalNamespaceInjector
    : OfficialPayPalNamespaceInjector,
});

Container.set({
  id: PayPalTokenSourceToken,
  type: MessageBusPayPalTokenSource,
});

Container.set({
  id: PayPalTokenPublisherToken,
  type: MessageBusPayPalTokenPublisher,
});

Container.set({
  id: PayPalJwtListenerToken,
  type: MessageBusPayPalJwtListener,
});

/**
 * PayPal Dependencies End Here
 */
Container.import([
  APMClientInitializer,
  ApplePayClientInitializer,
  ApplePayClientInitializer,
  GooglePayClientInitializer,
  PayPalClientInitializer,
  PaymentResultSubmitterSubscriber,
  PaymentResultSubmitterSubscriber,
  PreventNavigationPopup,
  PreventNavigationPopup,
  SentryBreadcrumbsSender,
  TokenizedCardClientInitializer,
]);

if (environment.testEnvironment) {
  Container.set({ id: HttpClient, type: HttpClient });
}

// if (environment.CLICK_TO_PAY.USE_STUBS) {
//   Container.import([TisaSrcProvider]);
// } else {
//   Container.import([VisaSrcProvider, MastercardSrcProvider]);
// }

Container.import([VisaSrcProvider, MastercardSrcProvider]);

Container.set({
  id: ApplePayButtonFactoryToken,
  type: environment.APPLEPAY.USE_UI_MOCK
    ? OfficialApplePayButtonFactory // TODO: Change this to MockedApplePayButtonFactory
    : OfficialApplePayButtonFactory,
});

Container.set({
  id: ApplePayButtonFactoryToken,
  type: OfficialApplePayButtonFactory,
});

Container.set({
  id: ApplePayApplePayEventBinderToken,
  type: DefaultApplePayEventBinder,
});

Container.set({
  id: IApplePayNamespace,
  type: resolveApplePayNamespaceType(environment.APPLEPAY.USE_UI_MOCK),
});

function resolveApplePayNamespaceType(
  useMock: boolean,
): new (window: IWindow) => IApplePayNamespace {
  return useMock ? MockedApplePayNamespace : OfficialApplePaySession;
}
