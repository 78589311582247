import { Inject, Service } from "typedi";
import { BehaviorSubject, Observable, Subject, firstValueFrom, of } from "rxjs";
import {
  OnClickActions,
  OnInitActions,
  PayPalNamespace,
} from "@paypal/paypal-js";

import { IPayPalConfig } from "../models/IPayPalConfig";
import { IPayPalOnClickActions } from "../models/IPayPalOnClickActions";
import { IPayPalOnClickData } from "../models/IPayPalOnClickData";
import { PayPalClickResult } from "../models/PayPalClickResult";
import { type IPayPalTokenSource } from "../models/IPayPalTokenSource";
import {
  PayPalTokenPublisherToken,
  PayPalTokenSourceToken,
} from "../../../client/dependency-injection/InjectionTokens";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { PayPalPaymentMethodName } from "../constants/PayPalConstants";
import { IPayPalOnShippingChangedActions } from "../models/IPayPalOnShippingChangedActions";
import { IPayPalShippingAddress } from "../models/IPayPalShippingAddress";
import { ConfigProvider } from "../../../shared/services/config-provider/ConfigProvider";
import { PaymentStatus } from "../../../application/core/services/payments/PaymentStatus";
import { PaymentResultHandler } from "../../../application/core/services/payments/PaymentResultHandler";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { EventScope } from "../../../client/integrations/constants/EventScope";
import { IPayPalInjector } from "./IPayPalInjector";
import { CreateOrderResolver } from "./CreateOrderResolver";
import { SubmitOrderResolver } from "./SubmitOrderResolver";
import { PayPalOnClickActions } from "./PayPalOnClickActions";
import { StatefulPayPalButtons } from "./StatefulPayPalButtons";
import { PayPalFundingSourceUtils } from "./PayPalFundingSourceUtils";
import { PayPalAddressChangeResult } from "./PayPalAddressChangeResult";
import { ShippingChangedResolver } from "./ShippingChangedResolver";
import { type IPayPalTokenPublisher } from "./IPayPalTokenPublisher";

/**
 * This class manages the interaction with the PayPal SDK
 * on the parent frame. Here we ensure that all button events
 * are correctly rendered, orchestrated and managed.
 */
@Service()
export class PayPalParentFrameClient {
  private config: IPayPalConfig;
  private readonly buttonStateTracker = new BehaviorSubject(true);
  private onInitTracker: Set<Record<string, unknown>> = new Set<
    Record<string, unknown>
  >();

  private totalEligibleButtons = 0;

  constructor(
    private readonly paypalInjector: IPayPalInjector,
    @Inject(PayPalTokenSourceToken)
    private readonly tokenSource: IPayPalTokenSource,
    @Inject(PayPalTokenPublisherToken)
    private readonly tokenPublisher: IPayPalTokenPublisher,
    private readonly messageBus: IMessageBus,
    private readonly configProvider: ConfigProvider,
    private readonly paymentResultHandler: PaymentResultHandler,
  ) {
    this.onPaymentConfirmedWithinModal =
      this.onPaymentConfirmedWithinModal.bind(this);
  }

  initialize(
    config: IPayPalConfig,
    liveStatus: number,
  ): Observable<IPayPalConfig> {
    this.config = config;
    this.loadAndRenderPayPalButton(config, liveStatus);

    return of(config);
  }

  private async loadAndRenderPayPalButton(
    config: IPayPalConfig,
    liveStatus: number,
  ): Promise<void> {
    try {
      const paypal = await this.paypalInjector.inject(config, liveStatus);
      this.totalEligibleButtons = 0; // Reset count before rendering
      await this.renderPayPalButton(paypal, config);
    } catch (error) {
      console.error("Failed to load the PayPal JS SDK script", error);
    }
  }

  private async renderPayPalButton(
    paypal: PayPalNamespace,
    config: IPayPalConfig,
  ) {
    // For each button config we received
    config.buttons.forEach((buttonConfig) => {
      // Then for each shared group of config
      buttonConfig.fundingSources.forEach(async (fundingSource) => {
        const fs = PayPalFundingSourceUtils.find(fundingSource);

        const button = paypal.Buttons({
          style: buttonConfig.style,
          fundingSource: fs,
          onInit: this.onPayPalInit.bind(this),
          createOrder: this.onModalReadyForOrderDetails.bind(this),
          onApprove: this.onBeforePaymentConfirmedWithinModal.bind(this),
          onClick: this.onPayPalPaymentButtonClicked.bind(this),
          onCancel: this.onPayPalPaymentCancel.bind(this),
          onError: this.onPayPalPaymentError.bind(this),
          // When the callback is attached, there's an extra loading screen
          // but it isn't there when it's undefnined.
          onShippingChange: config.callbacks.onShippingChanged
            ? this.onPayPalShippingAddressChanged.bind(this)
            : undefined,
        });

        if (!button.isEligible()) {
          return;
        }

        try {
          this.totalEligibleButtons += 1; // Increment for each eligible button
          await button.render(`#${buttonConfig.container}`);
        } catch (error) {
          console.error("Failed to render the PayPal Button", error);
        }
      });
    });
  }

  private async onPayPalShippingAddressChanged(data, actions) {
    const notification = new Subject<PayPalAddressChangeResult>();
    const userActions: IPayPalOnShippingChangedActions =
      new ShippingChangedResolver(notification);

    const address: IPayPalShippingAddress = {
      city: data.shipping_address.city,
      countryCode: data.shipping_address.country_code,
      postalCode: data.shipping_address.postal_code,
      state: data.shipping_address.state,
    };

    const promise = firstValueFrom(notification);
    if (this.config.callbacks.onShippingChanged) {
      this.config.callbacks.onShippingChanged(userActions, {
        selectedAddress: address,
      });
    }

    const result = await promise;

    if (result === PayPalAddressChangeResult.REJECTED) {
      return actions.reject();
    }

    return actions.resolve();
  }

  /**
   * This method is called when the PayPal SDK is initialized.
   * It adds the data to the onInitTracker and checks if all eligible buttons have been initialized.
   * If all buttons have been initialized, it calls the onInit callback function with the StatefulPayPalButtons.
   * It also subscribes to the buttonStateTracker and enables or disables the buttons based on its value.
   *
   * @param data - The data passed when the PayPal SDK is initialized.
   * @param actions - The actions available for the onInit event.
   */
  private onPayPalInit(data: Record<string, unknown>, actions: OnInitActions) {
    this.onInitTracker.add(data);
    if (
      this.onInitTracker.size === this.totalEligibleButtons &&
      this.config.callbacks.onInit
    ) {
      this.config.callbacks.onInit(
        new StatefulPayPalButtons(this.buttonStateTracker),
      );
    }

    this.buttonStateTracker.subscribe(async (shouldEnableButtons) => {
      if (shouldEnableButtons) {
        actions.enable();
      } else {
        actions.disable();
      }
    });
  }

  private async onPayPalPaymentButtonClicked(
    data: Record<string, unknown>,
    actions: OnClickActions,
  ) {
    const notification = new Subject<PayPalClickResult>();
    const userActions: IPayPalOnClickActions = new PayPalOnClickActions(
      notification,
    );
    const contextArgs: IPayPalOnClickData = {
      fundingSource: data["fundingSource"] as string,
      isEnabled: this.buttonStateTracker.getValue(),
    };

    const promise = firstValueFrom(notification);
    if (this.config.callbacks.onClick) {
      this.config.callbacks.onClick(userActions, contextArgs);
    }

    const result = await promise;
    if (result === PayPalClickResult.REJECTED) {
      return actions.reject();
    }
    return actions.resolve();
  }

  /**
   * A check to determine if the merchant has specified that
   * PayPal should be configured to not auto-start.
   *
   * Auto-start gets disabled by adding ["PAYPAL"] to disabledAutoPaymentStart.
   *
   * @param disabledAutoPaymentStart.
   *
   * @returns
   *  True if the merchant wants control over the payment
   * start action, rather than having the code execute it
   * directly
   */
  private isPaymentPrecheckEnabled(
    disabledAutoPaymentStart: string[],
  ): boolean {
    return (disabledAutoPaymentStart ?? [])
      .map((paymentMethodName) => paymentMethodName.toLowerCase())
      .includes(PayPalPaymentMethodName.toLowerCase());
  }

  /**
   * Triggers the onCreateOrder callback that was
   * provided by the merchant developer.
   */
  private async onModalReadyForOrderDetails() {
    const tokens = this.tokenSource.tokenStream();
    // Start the subscription process here
    const tokenSubscription = firstValueFrom(tokens);
    const resolver = new CreateOrderResolver(
      this.messageBus,
      this.tokenPublisher,
      this.configProvider.getConfig().jwt,
    );

    if (this.config.callbacks.onCreateOrder) {
      this.config.callbacks.onCreateOrder(resolver);
    }

    const paypalTokenId = await tokenSubscription;
    return paypalTokenId;
  }

  private async onBeforePaymentConfirmedWithinModal() {
    const disabledAutoPaymentStart: string[] =
      this.configProvider.getConfig()?.disabledAutoPaymentStart;

    if (this.isPaymentPrecheckEnabled(disabledAutoPaymentStart)) {
      const data: any = {
        name: PayPalPaymentMethodName,
      };
      data.paymentStart = this.onPaymentConfirmedWithinModal;

      this.messageBus.publish(
        {
          type: PUBLIC_EVENTS.PAYMENT_METHOD_PRE_CHECK,
          data,
        },
        EventScope.EXPOSED,
      );
    } else {
      await this.onPaymentConfirmedWithinModal();
    }
  }

  /**
   * Triggers the onConfirmPayment callback that was
   * provided by the merchant developer
   */
  private async onPaymentConfirmedWithinModal() {
    const resolver = new SubmitOrderResolver(this.messageBus);
    if (this.config.callbacks.onConfirmPayment) {
      this.config.callbacks.onConfirmPayment(resolver, {
        commit: this.config.scriptConfig.commit,
      });
    }
  }

  private onPayPalPaymentCancel() {
    this.paymentResultHandler.handle({
      status: PaymentStatus.CANCEL,
      paymentMethodName: PayPalPaymentMethodName,
      data: {
        suppressSubmitEvent: true,
        errorcode: "50003",
        errormessage: "The payment was cancelled",
      },
    });
  }

  private onPayPalPaymentError(error: Error) {
    if (this.isErrorFromUserClickingModalClose(error)) {
      if (this.isScenarioWherePayPalFiresBothCancelThenError(error)) {
        /**
         * In this scenario, we won't be doing anything.
         *
         * If the error we received is also known to raise a cancel event, we won't proceed
         * to trigger any of the error actions. We'll let the cancel callback handle all the work.
         *
         * This scenario can currently be reproduced by Closing the modal "just as the paypal text" starts to appear.
         */
      } else {
        this.onPayPalPaymentCancel();
      }
    } else {
      this.onPayPalPaymentErrorCalled(error);
    }
  }

  private onPayPalPaymentErrorCalled(error: Error) {
    this.paymentResultHandler.handle({
      status: PaymentStatus.ERROR,
      paymentMethodName: PayPalPaymentMethodName,
      data: {
        suppressSubmitEvent: true,
        errorcode: "50003",
        errormessage: "An error was encountered",
      },
    });
  }

  private isErrorFromUserClickingModalClose(error: Error) {
    return [
      "Window is closed, can not determine type",
      "Detected popup close",
    ].includes(error.message);
  }

  private isScenarioWherePayPalFiresBothCancelThenError(error: Error) {
    return ["Detected popup close"].includes(error.message);
  }
}
