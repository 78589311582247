import { Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../../application/core/models/constants/EventTypes";
import { IMessageBusEvent } from "../../../../application/core/models/IMessageBusEvent";
import { IMessageBus } from "../../../../application/core/shared/message-bus/IMessageBus";
import { MessageSubscriberToken } from "../../../../shared/dependency-injection/InjectionTokens";
import { IMessageSubscriber } from "../../../../shared/services/message-bus/interfaces/IMessageSubscriber";

import { IFrameQueryingService } from "../../../../shared/services/message-bus/interfaces/IFrameQueryingService";
import { IPayPalConfig } from "../../models/IPayPalConfig";
import { PayPalParentFrameClient } from "../PayPalParentFrameClient";
import { ConfigProvider } from "../../../../shared/services/config-provider/ConfigProvider";

@Service({ id: MessageSubscriberToken, multiple: true })
export class PayPalClientInitializer implements IMessageSubscriber {
  constructor(
    private readonly paypalClient: PayPalParentFrameClient,
    private readonly frameQueryingService: IFrameQueryingService,
    private readonly configProvider: ConfigProvider,
  ) {}

  register(messageBus: IMessageBus): void {
    this.frameQueryingService.whenReceive(
      PUBLIC_EVENTS.PAYPAL_CLIENT_INIT,
      (event: IMessageBusEvent<IPayPalConfig>) => {
        // if we don't use the config provider, then we won't get the real object with the callback implementationss
        const config = this.configProvider.getConfig();

        return this.paypalClient.initialize(config.paypal, config.livestatus);
      },
    );
  }
}
