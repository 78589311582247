export enum ErrorTypeName {
  ERROR = "Error",
  GATEWAY_ERROR = "GatewayError",
  TIMEOUT_ERROR = "TimeoutError",
  REQUEST_TIMEOUT_ERROR = "RequestTimeoutError",
  CARDINAL_ERROR = "CardinalError",
  MISCONFIGURATION_ERROR = "MisconfigurationError",
  PAYMENT_ERROR = "PaymentError",
  FRAME_COMMUNICATION_ERROR = "FrameCommunicationError",
  GATEWAY_FETCH_ERROR = "GatewayFetchError",
}
