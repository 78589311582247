import { from, Observable, of, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Service } from "typedi";
import { DomMethods } from "../../../../application/core/shared/dom-methods/DomMethods";
import { environment } from "../../../../environments/environment";
import {
  GooglePayProductionEnvironment,
  GooglePayTestEnvironment,
} from "../../../../integrations/google-pay/models/IGooglePayConfig";
import { IGooglePayIsReadyToPayRequest } from "../../../../integrations/google-pay/models/IGooglePayIsReadyToPayRequest";
import { IGooglePaySessionPaymentsClient } from "../../../../integrations/google-pay/models/IGooglePaySessionPaymentsClient";
import { IConfig } from "../../../../shared/model/config/IConfig";
import { IIsReadyToPayResponse } from "../../../../integrations/google-pay/models/IIsReadyToPayResponse";
import { IGooglePaySdkProvider } from "./IGooglePaySdkProvider";

@Service()
export class GooglePaySdkProvider implements IGooglePaySdkProvider {
  private readonly SCRIPT_ADDRESS = environment.GOOGLE_PAY.GOOGLE_PAY_URL;
  private readonly SCRIPT_TARGET: string = "head";

  setupSdk$(config: IConfig): Observable<IGooglePaySessionPaymentsClient> {
    let googlePaySdkInstance: IGooglePaySessionPaymentsClient;

    return this.insertGooglePayLibrary().pipe(
      map(() => {
        googlePaySdkInstance = this.getGooglePaySdkInstance(config);

        return googlePaySdkInstance;
      }),
      switchMap((googlePaySdk: IGooglePaySessionPaymentsClient) => {
        return from(
          googlePaySdk.isReadyToPay(this.getGoogleIsReadyToPayRequest(config)),
        );
      }),
      switchMap((isReadyToPayResponse: IIsReadyToPayResponse) => {
        if (!isReadyToPayResponse.result) {
          return throwError(
            () =>
              new Error(
                "The GooglePay library is unable to provide the necessary components to accept the payment. This may be an issue with the configuration of the browser or device.",
              ),
          );
        }
        return of(googlePaySdkInstance);
      }),
    );
  }

  private insertGooglePayLibrary(): Observable<Element> {
    return DomMethods.insertScript(this.SCRIPT_TARGET, {
      src: this.SCRIPT_ADDRESS,
    });
  }

  private getGooglePayEnvironment(config: IConfig): string {
    const paymentRequest = config.googlePay.paymentRequest;

    return config.googlePay.paymentRequest.environment
      ? paymentRequest.environment
      : environment.production
        ? GooglePayProductionEnvironment
        : GooglePayTestEnvironment;
  }

  private getGooglePaySdkInstance(
    config: IConfig,
  ): IGooglePaySessionPaymentsClient {
    return new (
      window as unknown as IWindow
    ).google.payments.api.PaymentsClient({
      environment: this.getGooglePayEnvironment(config),
      paymentDataCallbacks:
        config.googlePay.paymentRequest?.paymentDataCallbacks,
    });
  }

  private getGoogleIsReadyToPayRequest(
    config: IConfig,
  ): IGooglePayIsReadyToPayRequest {
    const { apiVersion, apiVersionMinor, allowedPaymentMethods } =
      config.googlePay.paymentRequest;

    return {
      apiVersion,
      apiVersionMinor,
      allowedPaymentMethods,
    };
  }
}
