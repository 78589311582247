import { Token } from "typedi";
import { ISrcProvider } from "../../integrations/click-to-pay/digital-terminal/ISrcProvider";
import { IPayPalTokenSource } from "../../integrations/paypal/models/IPayPalTokenSource";
import { IPayPalJwtListener } from "../../integrations/paypal/client/IPayPalJwtListener";
import { IPayPalTokenPublisher } from "../../integrations/paypal/client/IPayPalTokenPublisher";
import { IGatewayPayPalService } from "../../integrations/paypal/application/IGatewayPayPalService";
import { IHttpPayPalTransportService } from "../../integrations/paypal/application/IHttpPayPalTransportService";
import { IApplePayButtonFactory } from "../../integrations/apple-pay/client/services/button/IApplePayButtonFactory";
import { IApplePayEventBinder } from "../../integrations/apple-pay/client/services/payment/IApplePayEventBinder";
import { IApplePayGatewayClient } from "../../integrations/apple-pay/application/services/apple-pay-payment-service/IApplePayGatewayClient";

export const SrcProviderToken = new Token<ISrcProvider>("src-provider");
export const PayPalTokenSourceToken = new Token<IPayPalTokenSource>(
  "paypal-token-source",
);

export const PayPalTokenPublisherToken = new Token<IPayPalTokenPublisher>(
  "paypal-token-publisher",
);

export const PayPalJwtListenerToken = new Token<IPayPalJwtListener>(
  "paypal-jwt-listener",
);

export const PayPalGatewayServiceToken = new Token<IGatewayPayPalService>(
  "paypal-gateway-service",
);

export const HttpPayPalTransportServiceToken =
  new Token<IHttpPayPalTransportService>("paypal-transport-service");

/**
 * ApplePay Tokens
 */

export const ApplePayButtonFactoryToken = new Token<IApplePayButtonFactory>(
  "applepay-button-factory",
);

export const ApplePayApplePayEventBinderToken = new Token<IApplePayEventBinder>(
  "applepay-event-binder",
);

export const ApplePayGatewayServiceToken = new Token<IApplePayGatewayClient>(
  "applepay-gateway-service",
);
