import { Container } from "typedi";
import { IMessageBus } from "../../application/core/shared/message-bus/IMessageBus";
import { MessageBusFactory } from "../../application/core/shared/message-bus/MessageBusFactory";
import { ApplePayReducer } from "../../application/core/store/reducers/apple-pay/ApplePayReducer";
import { InitialConfigReducer } from "../../application/core/store/reducers/initial-config/InitialConfigReducer";
import { IStore } from "../../application/core/store/IStore";
import { StoreFactory } from "../../application/core/store/StoreFactory";
import { ConfigReducer } from "../../application/core/store/reducers/config/ConfigReducer";
import { StorageReducer } from "../../application/core/store/reducers/storage/StorageReducer";
import { LocaleSubscriber } from "../../application/core/shared/translator/LocaleSubscriber";
import { ITranslationProvider } from "../../application/core/shared/translator/ITranslationProvider";
import { TranslationProvider } from "../../application/core/shared/translator/TranslationProvider";
import { ITranslator } from "../../application/core/shared/translator/ITranslator";
import { TranslatorWithMerchantTranslations } from "../../application/core/shared/translator/TranslatorWithMerchantTranslations";
import { IFrameQueryingService } from "../services/message-bus/interfaces/IFrameQueryingService";
import { FrameQueryingService } from "../services/message-bus/FrameQueryingService";
import { SentryReducer } from "../../application/core/store/reducers/sentry/SentryReducer";
import { IControlFrameInitializationRequest } from "../../application/core/shared/message-bus/IControlFrameInitializationRequest";
import { MessageBusControlFrameInitializationManager } from "../../application/core/shared/message-bus/MessageBusControlFrameInitializationManager";
import { IScriptOriginStream } from "../../application/core/services/st-codec/IScriptOriginStream";
import { ScriptOriginStream } from "../../application/core/services/st-codec/ScriptOriginStream";
import { IScriptOriginPublisher } from "../../application/core/services/st-codec/IScriptOriginPublisher";
import { ScriptOriginPublisher } from "../../application/core/services/st-codec/ScriptOriginPublisher";
import {
  MessageBusToken,
  StoreToken,
  TranslatorToken,
} from "./InjectionTokens";

Container.set({ id: IMessageBus, factory: [MessageBusFactory, "create"] });
Container.set({ id: MessageBusToken, factory: [MessageBusFactory, "create"] });
Container.set({ id: IStore, factory: [StoreFactory, "create"] });
Container.set({ id: StoreToken, factory: [StoreFactory, "create"] });
Container.set({ id: ITranslationProvider, type: TranslationProvider });
Container.set({ id: ITranslator, type: TranslatorWithMerchantTranslations });
Container.set({
  id: TranslatorToken,
  type: TranslatorWithMerchantTranslations,
});
Container.set({ id: IFrameQueryingService, type: FrameQueryingService });
Container.set({ id: IScriptOriginStream, type: ScriptOriginStream });
Container.set({ id: IScriptOriginPublisher, type: ScriptOriginPublisher });
Container.set({
  id: IControlFrameInitializationRequest,
  type: MessageBusControlFrameInitializationManager,
});
Container.import([
  ConfigReducer,
  StorageReducer,
  ApplePayReducer,
  InitialConfigReducer,
  LocaleSubscriber,
  SentryReducer,
]);
