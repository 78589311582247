import { Container } from "typedi";
import { GooglePaySdkProviderMock } from "../client/integrations/google-pay/google-pay-sdk-provider/GooglePaySdkProviderMock";
import { IGooglePaySdkProvider } from "../client/integrations/google-pay/google-pay-sdk-provider/IGooglePaySdkProvider";
import { environment } from "../environments/environment";
import { CardinalProvider } from "../client/integrations/cardinal-commerce/CardinalProvider";
import { IHttpOptionsProvider } from "../application/core/services/st-transport/http-options-provider/IHttpOptionsProvider";
import { TestHttpOptionsProvider } from "../application/core/services/st-transport/http-options-provider/TestHttpOptionsProvider";
import { IApplePayNamespace } from "../integrations/apple-pay/client/models/IApplePayNamespace";
import { LegacyMockedApplePayNamespace } from "../integrations/apple-pay/client/mock/LegacyMockedApplePayNamespace";
import { MockCardinalProvider } from "./mocks/MockCardinalProvider";

if (environment.testEnvironment) {
  Container.set({ id: CardinalProvider, type: MockCardinalProvider });
  Container.set({ id: IGooglePaySdkProvider, type: GooglePaySdkProviderMock });
  Container.set({
    id: IApplePayNamespace,
    type: LegacyMockedApplePayNamespace,
  });
  Container.set({ id: IHttpOptionsProvider, type: TestHttpOptionsProvider });
}
