import { IAllowedStyles } from "../../models/IAllowedStyles";

export const frameAllowedStyles: IAllowedStyles = {
  "background-color-body": { property: "background-color", selector: "body" },
  "color-body": { property: "color", selector: "body" },
  "font-size-body": { property: "font-size", selector: "body" },
  "line-height-body": { property: "line-height", selector: "body" },
  "space-inset-body": { property: "padding", selector: "body" },
  "space-outset-body": { property: "margin", selector: "body" },
};
