export const headerTemplate = (closeButtonId: string, logo: string) => {
  return `<div class='st-ctp-prompt__header'>
    <div class="st-ctp-prompt__logo">
      <img src='${logo}' class='st-ctp-prompt__logo--otp' alt=''>
      <span>Click To Pay</span>
    </div>
    <span class='st-ctp-prompt__close st-ctp-prompt__close--otp' id='${closeButtonId}'>&times;</span>
   </div>`;
};

export const submitButtonTemplate = (label: string) => {
  return `<button type='submit' class='st-hpp-prompt__button'>${label}</button>`;
};

export const codeInputsTemplate = (
  fieldClass: string,
  errorFieldClass: string,
  otpInputsNames: string[],
) => {
  return `<div class='${fieldClass}'>
    ${otpInputsNames
      .map(
        (name) =>
          `<input type='text' inputmode='numeric' required size='1' pattern='[0-9]{1}' name='${name}' class='st-ctp-prompt__otp-input' autocomplete='off' >`,
      )
      .join("")}
    <span class='${errorFieldClass} st-hpp-prompt__otp-input-error'></span>
  </div>`;
};
