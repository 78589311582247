export class ScriptHostLocator {
  /**
   * Determines the HOST url of a script that has been listed on a page.
   *
   * @param scripts The script elements located on the page
   * @param libraryName The JS library to locate. The name should be the exact name of the script. No need to include a forward slash before it.
   * @returns The HOST url of the first matching script, or null if none could be found.
   */
  static getScriptHosts(
    scripts: HTMLCollectionOf<HTMLScriptElement>,
    libraryName: string,
  ): string[] {
    const matchingHosts: string[] = [];

    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].src;
      if (!src) {
        continue;
      }

      const srcWithoutQueryParam = src.toLowerCase().split("?")[0];
      if (srcWithoutQueryParam.endsWith(`/${libraryName}`)) {
        const url = new URL(src);
        const resource = url.pathname.replace(`/${libraryName}`, "");
        const baseUrl = `${url.protocol}//${url.host}${resource}`;
        matchingHosts.push(baseUrl);
      }
    }

    return matchingHosts; // Return null if no matching URL is found
  }
}
