import { Observable, catchError, switchMap, throwError } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Inject, Service } from "typedi";
import { ISrcProvider } from "../../ISrcProvider";
import { ISrc } from "../../ISrc";
import { ConfigProvider } from "../../../../../shared/services/config-provider/ConfigProvider";
import { environment } from "../../../../../environments/environment";
import { DomMethods } from "../../../../../application/core/shared/dom-methods/DomMethods";
import { SrcName } from "../../SrcName";
import { SrcProviderToken } from "../../../../../client/dependency-injection/InjectionTokens";
import { SentryService } from "../../../../../shared/services/sentry/SentryService";
import { type IInternalsMonitor } from "../../../../../application/core/services/monitoring/IInternalsMonitor";
import { VisaSrcWrapper } from "./VisaSrcWrapper";

@Service({ id: SrcProviderToken, multiple: true })
export class VisaSrcProvider implements ISrcProvider {
  private src: Observable<ISrc>;

  constructor(
    private configProvider: ConfigProvider,
    private sentryService: SentryService,
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
  ) {
    this.src = this.configProvider.getConfig$().pipe(
      map((config) =>
        config.livestatus
          ? environment.CLICK_TO_PAY.VISA.PROD
          : environment.CLICK_TO_PAY.VISA.SANDBOX,
      ),
      switchMap((sdkConfig) => {
        return DomMethods.insertScript("head", {
          src: sdkConfig.SRC_SDK_URL,
        }).pipe(
          this.sentryService.captureAndReportResourceLoadingTimeout(
            "Visa Click To Pay SDK script load timeout",
            environment.CLICK_TO_PAY.SCRIPT_LOAD_TIMEOUT,
          ),
          catchError((error) => {
            this.internalMonitor.recordIssue(error);
            return throwError(() => error);
          }),
          map(() => new VisaSrcWrapper(sdkConfig.SRC_INITIATOR_ID)),
        );
      }),
      shareReplay(1),
    );
  }

  getSrcName(): SrcName {
    return SrcName.VISA;
  }

  getSrc(): Observable<ISrc> {
    return this.src;
  }
}
