import { ApplePayMockModal } from "./ApplePayMockModal";
import { MockedApplePaySession } from "./MockedApplePaySession";

export class ApplePayMockUiController {
  private modal: ApplePayMockModal;

  public open(sessionMock: MockedApplePaySession): void {
    this.modal = new ApplePayMockModal();
    this.modal.setAuthorizeCallback(sessionMock.proceedPayment);
    this.modal.setCancelCallback(() => this.close(sessionMock));
    this.modal.setErrorCallback(() => this.close(sessionMock));
    this.modal.open();
  }

  public unlockPaymentButton(): void {
    this.modal.unlock();
  }

  public close(applePaySession: MockedApplePaySession): void {
    applePaySession.oncancel(undefined);
    this.modal.close();
  }
}
