import Joi from "joi";
import { GooglePayConfigName } from "../../../integrations/google-pay/models/IGooglePayConfig";
import { TokenizedCardPaymentConfigName } from "../../../integrations/tokenized-card/models/ITokenizedCardPaymentMethod";
import { PayPalConfigName } from "../../../integrations/paypal/constants/PayPalConstants";
import { ApplePaySchema } from "./apple-pay-schema/ApplePaySchema";
import { GooglePaySchema } from "./google-pay-schema/GooglePaySchema";
import { PayPalSchema } from "./paypal-schema/PayPalSchema";

export const ConfigSchema: Joi.ObjectSchema = Joi.object().keys({
  analytics: Joi.boolean(),
  animatedCard: Joi.boolean(),
  applePay: ApplePaySchema,
  buttonId: Joi.string().allow(""),
  stopSubmitFormOnEnter: Joi.boolean(),
  cancelCallback: Joi.any(),
  componentIds: Joi.object()
    .keys({
      animatedCard: Joi.string().allow("").default("st-animated-card"),
      cardNumber: Joi.string().allow("").default("st-card-number"),
      expirationDate: Joi.string().allow("").default("st-expiration-date"),
      notificationFrame: Joi.string()
        .allow("")
        .default("st-notification-frame"),
      securityCode: Joi.string().allow("").default("st-security-code"),
    })
    .allow({})
    .default({}),
  /**
   * Note that this is no longer supported in the config object, but we need it
   * temporarily for backwards compatibility, as we know HPP make reference to it.
   */
  cybertonicaApiKey: Joi.string()
    .allow("")
    .warning("deprecate.error", {
      reason: "Library no longer supports Cybertonica",
    })
    .messages({
      "deprecate.error":
        "The config option cybertonicaApiKey is deprecated and will be removed in a future release",
    }),
  components: Joi.object()
    .keys({
      defaultPaymentType: Joi.string().allow(""),
      paymentTypes: Joi.array().items(Joi.string().allow("")),
      startOnLoad: Joi.boolean().allow(""),
    })
    .default({}),
  datacenterurl: Joi.string()
    .allow(
      "",
      "https://webservices.securetrading.us",
      "https://webservices.securetrading.net",
      "https://webservices.securetrading.us/jwt/",
      "https://webservices.securetrading.net/jwt/",
    )
    .warning("deprecate.error", { reason: "wrong value has been provided" })
    .messages({
      "deprecate.error":
        "{#label} is incorrect because {#reason}. Please use https://webservices.securetrading.us/jwt/ or https://webservices.securetrading.net/jwt/",
    }),
  deferInit: Joi.boolean(),
  disableNotification: Joi.boolean().default(false),
  errorCallback: Joi.any(),
  errorReporting: Joi.boolean()
    .valid(true, false)
    .default(true)
    .optional()
    .error((errors: any) => {
      return errors.map((error) => {
        if (error.code === "any.only") {
          error.message = `Unsupported value specified for errorReporting. Options are true or false.`;
        }
        return error;
      });
    }),
  fieldsToSubmit: Joi.array().items(
    Joi.string().valid("pan", "expirydate", "securitycode"),
  ),
  [GooglePayConfigName]: GooglePaySchema,
  [PayPalConfigName]: PayPalSchema,
  formId: Joi.string(),
  init: Joi.object({
    cachetoken: Joi.string()
      .allow("")
      .warning("deprecate.error", { reason: "it is no longer supported" })
      .messages({
        "deprecate.error": "{#label} is deprecated because {#reason}",
      }),
    threedinit: Joi.string()
      .allow("")
      .warning("deprecate.error", { reason: "it is no longer supported" })
      .messages({
        "deprecate.error": "{#label} is deprecated because {#reason}",
      }),
  }),
  jwt: Joi.string().allow(""),
  livestatus: Joi.number().valid(0, 1),
  origin: Joi.string().allow(""),
  panIcon: Joi.boolean(),
  placeholders: Joi.object().keys({
    pan: Joi.string().allow(""),
    securitycode: Joi.string().allow(""),
    expirydate: Joi.string().allow(""),
  }),
  styles: Joi.object(),
  submitCallback: Joi.any(),
  successCallback: Joi.any(),
  submitFields: Joi.array(),
  submitOnCancel: Joi.boolean(),
  submitOnError: Joi.boolean(),
  submitOnSuccess: Joi.boolean(),
  translations: Joi.object(),
  disabledAutoPaymentStart: Joi.array(),
  [TokenizedCardPaymentConfigName]: Joi.any(),
});
