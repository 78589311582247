import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Inject, Service } from "typedi";
import { MessageSubscriberToken } from "../../../../shared/dependency-injection/InjectionTokens";
import { IMessageSubscriber } from "../../../../shared/services/message-bus/interfaces/IMessageSubscriber";
import { ofType } from "../../../../shared/services/message-bus/operators/ofType";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { IMessageBusEvent } from "../../models/IMessageBusEvent";
import { IMessageBus } from "../../shared/message-bus/IMessageBus";
import { ofTypeList } from "../../../../shared/services/message-bus/operators/ofTypeList";
import { untilDestroy } from "../../../../shared/services/message-bus/operators/untilDestroy";
import { InternalsMonitorActivityCategories } from "../monitoring/InternalsMonitorActivityCategories";
import { type IInternalsMonitor } from "../monitoring/IInternalsMonitor";
import { MESSAGES_LIST_FOR_SENTRY_BREADCRUMBS_SENDER } from "./SentryBreadcrumbsSenderConfig";
import { ISentryBreadcrumbsSenderConfig } from "./ISentryBreadcrumbsSenderConfig";
import { ISentryBreadcrumbsMessageBusData } from "./ISentryBreadcrumbsMessageBusData";

@Service({ id: MessageSubscriberToken, multiple: true })
export class SentryBreadcrumbsSender implements IMessageSubscriber {
  constructor(
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
  ) {}

  register(messageBus: IMessageBus): void {
    const destroy$: Observable<void> = messageBus.pipe(
      ofType(PUBLIC_EVENTS.DESTROY),
      untilDestroy(messageBus),
    );
    const eventsToWatch: string[] = Object.keys(
      MESSAGES_LIST_FOR_SENTRY_BREADCRUMBS_SENDER,
    );

    messageBus
      .pipe(ofTypeList(eventsToWatch), takeUntil(destroy$))
      .subscribe(
        (event: IMessageBusEvent<ISentryBreadcrumbsMessageBusData>) => {
          const eventConfig: ISentryBreadcrumbsSenderConfig =
            MESSAGES_LIST_FOR_SENTRY_BREADCRUMBS_SENDER[event.type];
          if (event?.type === PUBLIC_EVENTS.UPDATE_JWT) {
            this.internalMonitor.recordActivity(
              InternalsMonitorActivityCategories.JWT_UPDATES,
            );
          } else {
            const context = eventConfig.message(
              event?.data?.name || event?.data?.customMessage,
            );
            this.internalMonitor.recordActivity(
              eventConfig.sentryBreadcrumbsCategories,
              { context },
            );
          }
        },
      );
  }
}
