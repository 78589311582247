/* istanbul ignore file */
import { IApplePayPaymentAuthorizationResult } from "../models/IApplePayPaymentAuthorizationResult";
import { IApplePayPaymentAuthorizedEvent } from "../models/IApplePayPaymentAuthorizedEvent";
import { IApplePaySession } from "../models/IApplePaySession";
import { IApplePayValidateMerchantEvent } from "../models/IApplePayValidateMerchantEvent";
import { ApplePayMockUiController } from "./ApplePayMockUiController";

export class MockedApplePaySession implements IApplePaySession {
  private mockUserInterface: ApplePayMockUiController;

  oncancel: (event: Event) => void;
  onpaymentauthorized: (event: IApplePayPaymentAuthorizedEvent) => void;
  onvalidatemerchant: (event: IApplePayValidateMerchantEvent) => void;

  constructor() {
    this.mockUserInterface = new ApplePayMockUiController();
    this.proceedPayment = this.proceedPayment.bind(this);
  }

  public begin(): void {
    this.mockUserInterface.open(this);
    this.onvalidatemerchant({
      validationURL: "https://webservices-mock.securetrading.net:6443/jwt/",
    });
  }

  public abort(): void {
    this.mockUserInterface.close(this);
  }

  public proceedPayment() {
    const data = {
      payment: "mockapplepaydata",
      status: "SUCCESS",
    };
    if (data.status === "SUCCESS") {
      // @ts-ignore
      this.onpaymentauthorized(data);
    } else {
      // @ts-ignore
      this.oncancel(data);
    }
  }

  public completeMerchantValidation(merchantSession: unknown): void {
    this.mockUserInterface.unlockPaymentButton();
  }

  public completePayment(status: IApplePayPaymentAuthorizationResult): void {
    this.mockUserInterface.close(this);
  }
}
