export enum InternalsMonitorActivityCategories {
  GATEWAY_REQUEST = "GATEWAY_REQUEST",
  GATEWAY_RESPONSE = "GATEWAY_RESPONSE",
  EXPOSED_EVENTS = "EXPOSED_EVENTS",
  THREE_DS = "THREE_DS",
  JWT_UPDATES = "JWT_UPDATES",
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
  CONFIG = "CONFIG",
  INITIAL_CONFIG = "INITIAL_CONFIG",
}
