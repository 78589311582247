import { Service } from "typedi";
import { IApplePaySession } from "../../models/IApplePaySession";
import { IApplePayConfigObject } from "../config/IApplePayConfigObject";
import { ApplePayCancelAction } from "./ApplePayCancelAction";
import { ApplePayMerchantValidationAction } from "./ApplePayMerchantValidationAction";
import { ApplePayPaymentAuthorizationAction } from "./ApplePayPaymentAuthorizationAction";
import { IApplePayEventBinder } from "./IApplePayEventBinder";

@Service()
export class DefaultApplePayEventBinder implements IApplePayEventBinder {
  constructor(
    private validationAction: ApplePayMerchantValidationAction,
    private authorizationAction: ApplePayPaymentAuthorizationAction,
    private cancelAction: ApplePayCancelAction,
  ) {}

  bindAll(session: IApplePaySession, config: IApplePayConfigObject) {
    // Binds the behaviour for the 'onvalidatemerchant' callback from ApplePay.
    session.onvalidatemerchant = (e) =>
      this.validationAction.execute(session, config.validateMerchantRequest, e);

    // Binds the behaviour for the 'onpaymentauthorized' callback from ApplePay.
    session.onpaymentauthorized = (e) =>
      this.authorizationAction.execute(session, config.formId, e);

    // Binds the behaviour for the 'oncancel' callback from ApplePay.
    session.oncancel = (e) => this.cancelAction.execute();
  }
}
