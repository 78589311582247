import Joi from "joi";
import { PayPalFundingSourceUtils } from "../../../../integrations/paypal/client/PayPalFundingSourceUtils";

const paypalCallbacksSchema = Joi.object().keys({
  onInit: Joi.function().optional(),
  onClick: Joi.function().optional(),
  onCreateOrder: Joi.function().optional(),
  onConfirmPayment: Joi.function().optional(),
  onShippingChanged: Joi.function().optional(),
});

export const PayPalSchema = Joi.object().keys({
  buttons: Joi.array().items(
    Joi.object().keys({
      fundingSources: Joi.array()
        .items(
          Joi.string().custom((value, helpers) => {
            if (
              !PayPalFundingSourceUtils.getSupportedSources().includes(value)
            ) {
              return helpers.message({
                custom: `The given funding source of '${value}' is not supported by ST.js. The button will not be rendered.`,
              });
            }
            return value; // indicates the value is valid
          }, "Funding Source Validation"),
        )
        .required()
        .min(1)
        .messages({
          "array.base": "fundingSources must be an array",
          "array.min": "fundingSources cannot be empty",
          "any.required": "fundingSources is a required field",
        }),
      style: Joi.object().optional(),
      container: Joi.string().required().min(1).messages({
        "string.base": "container must be a string",
        "string.empty": "container cannot be empty",
        "string.min": "container cannot be empty",
        "any.required": "container is a required field",
      }),
    }),
  ),
  scriptConfig: Joi.object().keys({
    currency: Joi.string().required().min(3).max(3),
    debug: Joi.boolean().valid(true, false).default(false),
    merchantId: Joi.string().required(),
    locale: Joi.string().allow(null),
    commit: Joi.boolean().valid(true, false).default(true),
    dataCspNonce: Joi.string().allow(null),
    dataClientToken: Joi.string().allow(null),
    dataPageType: Joi.string().allow(null),
    buyerCountry: Joi.string().optional().min(2).max(2),
    intent: Joi.string().optional(),
  }),
  callbacks: paypalCallbacksSchema.optional(),
});
