import { Inject } from "typedi";
import { ScriptHostLocator } from "../../shared/utils/ScriptHostLocator";
import { type IInternalsMonitor } from "../monitoring/IInternalsMonitor";
import { IScriptOriginPublisher } from "./IScriptOriginPublisher";
import { IScriptOriginStream } from "./IScriptOriginStream";

const UrlsToIgnore = [
  // URL of JS V1: we know that some merchants left the old import in their code, which appears before
  // the V3 import. If we do not ignore this, then the script origin tagger logic would say that our V3
  // script was downloaded from here
  "https://webservices.securetrading.net/js",
];

export class ScriptOriginPublisher implements IScriptOriginPublisher {
  constructor(
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
    private stream: IScriptOriginStream,
  ) {}

  private filter(urlArray: string[]): string[] {
    return urlArray.filter((url) => !UrlsToIgnore.includes(url));
  }

  findThenPublish(
    scripts: HTMLCollectionOf<HTMLScriptElement>,
    scriptName: string,
  ) {
    const baseUrls: string[] = this.filter(
      ScriptHostLocator.getScriptHosts(scripts, scriptName),
    );

    if (baseUrls.length === 0) {
      this.internalMonitor.recordIssue(
        new Error(
          "Unable to find ST.js on the page, and therefore setting ScriptOriginTag to `UNKNOWN`",
        ),
        scripts,
      );
    }

    this.stream.push(baseUrls[0] ?? null);
  }
}
