import { Service } from "typedi";
import { InterFrameCommunicator } from "../../../../shared/services/message-bus/InterFrameCommunicator";
import { FrameIdentifier } from "../../../../shared/services/message-bus/FrameIdentifier";
import { type IInternalsMonitor } from "./IInternalsMonitor";
import { InternalsMonitorBroadcaster } from "./InternalsMonitorBroadcaster";

/**
 * Global class for referencing an non control frame monitor solution. specially for Broadcast monitor
 *
 */
@Service()
export class NonControlFrameInternalsMonitorFactory {
  /**
   * New instance of the factory.
   * @param interFrameCommunicator The global inter-frame communicator.
   */
  constructor(private interFrameCommunicator: InterFrameCommunicator) {}

  /**
   * IMPORTANT :- To avoid doubling the number of error events in Sentry due to the logic implemented
   * in ControlFrameBootstrap for the PUBLIC_EVENTS.APP_ERROR event, do not use CompositeInternalMonitor
   * with the InternalsMonitorBroadcaster class.
   *
   * Creates a new instance of IInternalsMonitor that will be used exclusively for identified frame
   * throughout the library's lifecylce.
   * @param frameIdentifier A helper class that identifies whcih frame this
   * code is executing within.
   * @returns
   *  The monitor instance most appropriate for this application.
   *
   */
  public create(frameIdentifier?: FrameIdentifier): IInternalsMonitor {
    if (frameIdentifier.isControlFrame()) {
      throw new Error(
        "The factory can only be used against the NON control frame",
      );
    }
    return new InternalsMonitorBroadcaster(this.interFrameCommunicator);
  }
}
