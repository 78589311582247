import { PayPalScriptOptions } from "@paypal/paypal-js";

import { environment } from "../../../environments/environment";
import { IPayPalConfig } from "../models/IPayPalConfig";

import { PayLaterFundingSourceName } from "../constants/PayPalConstants";
import { PayPalFundingSourceUtils } from "./PayPalFundingSourceUtils";

import { PayPalIntent } from "./PayPalIntent";
/**
 * Maps our own Config fields to those of PayPal.
 */
export class PayPalConfigMapper {
  static mapOwnConfigToPayPal(
    config: IPayPalConfig,
    liveStatus: number,
  ): PayPalScriptOptions {
    const {
      currency,
      merchantId,
      locale,
      commit,
      intent,
      dataCspNonce,
      dataClientToken,
      dataPageType,
      debug,
      buyerCountry,
    } = config.scriptConfig;

    const isLive = Number(liveStatus) === 1;
    const clientId = isLive
      ? environment.PAYPAL.CLIENT_ID.LIVE
      : environment.PAYPAL.CLIENT_ID.SANDBOX;
    const integrationDate = isLive
      ? environment.PAYPAL.INTEGRATION_DATE.LIVE
      : environment.PAYPAL.INTEGRATION_DATE.SANDBOX;

    const paypalScriptConfig: PayPalScriptOptions = {
      components: "buttons,funding-eligibility",
      clientId: clientId as string,
      currency,
      merchantId: merchantId,
      locale,
      integrationDate: integrationDate as string,
      intent: intent ?? PayPalIntent.AUTHORIZE,
      vault: false,
      commit,
      dataPartnerAttributionId: environment.PAYPAL.DATA_PARTNER_ATTRIBUTION_ID,
    };

    const fundingSources = config.buttons.flatMap((x) => x.fundingSources);
    paypalScriptConfig.enableFunding = Array.from(
      this.createEnabledFundingSourcesList(fundingSources),
    ).join(",");

    paypalScriptConfig.disableFunding = Array.from(
      this.createDisabledFundingSourceList(fundingSources),
    ).join(",");

    // Add buyer-country only if liveStatus is not equal to 1
    if (buyerCountry) {
      if (isLive) {
        throw new Error(
          "The property 'buyerCountry' is only allowed to be used when livestatus is set to 0",
        );
      } else {
        paypalScriptConfig.buyerCountry = buyerCountry;
      }
    }

    if (dataCspNonce) {
      paypalScriptConfig.dataCspNonce = dataCspNonce;
    }
    if (dataClientToken) {
      paypalScriptConfig.dataClientToken = dataClientToken;
    }
    if (dataPageType) {
      paypalScriptConfig.dataPageType = dataPageType;
    }
    if (debug) {
      paypalScriptConfig.debug = debug;
    }
    return paypalScriptConfig;
  }
  private static createEnabledFundingSourcesList(
    fundingSources: string[],
  ): Set<string> {
    const sourcesToEnable: Set<string> = new Set(fundingSources);

    if (sourcesToEnable.has(PayLaterFundingSourceName)) {
      PayPalFundingSourceUtils.getCreditFundingSources().forEach((s) =>
        sourcesToEnable.add(s),
      );
    }

    sourcesToEnable.delete(PayPalFundingSourceUtils.getDefault());
    return sourcesToEnable;
  }

  private static createDisabledFundingSourceList(
    fundingSources: string[],
  ): Set<string> {
    const sourcesToDisable: Set<string> = new Set(
      PayPalFundingSourceUtils.getDisableableSources(),
    );

    fundingSources.forEach((source) => {
      if (source === PayLaterFundingSourceName) {
        PayPalFundingSourceUtils.getCreditFundingSources().forEach((s) =>
          sourcesToDisable.delete(s),
        );
      } else {
        sourcesToDisable.delete(source);
      }
    });

    return sourcesToDisable;
  }
}
