/* istanbul ignore file */
import { Inject, Service } from "typedi";
import { IApplePayPaymentRequest } from "../models/apple-pay-payment-data/IApplePayPaymentRequest";
import { IApplePaySession } from "../models/IApplePaySession";
import { IApplePayNamespace } from "../models/IApplePayNamespace";
import { IApplePaySessionConstructor } from "../models/IApplePaySessionConstructor";
import { WINDOW } from "../../../../shared/dependency-injection/InjectionTokens";
import { MockedApplePaySession } from "./MockedApplePaySession";

@Service()
export class MockedApplePayNamespace implements IApplePayNamespace {
  constructor(@Inject(WINDOW) private window: IWindow) {
    // Hack because when using official applepay, the session gets added to window
    // and we access statuses via ApplePayStatus.
    window.ApplePaySession = {
      STATUS_SUCCESS: 1,
      STATUS_FAILURE: 2,
    } as IApplePaySessionConstructor;
  }

  isApplePaySessionAvailable(): boolean {
    return true;
  }

  canMakePayments(): boolean {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canMakePaymentsWithActiveCard(merchantId: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  supportsVersion(version: number): boolean {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createInstance(
    applePayVersion: number,
    applePayPaymentRequest: IApplePayPaymentRequest,
  ): IApplePaySession {
    return new MockedApplePaySession();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLatestSupportedApplePayVersion(): number {
    return 2;
  }
}
