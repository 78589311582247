import { Observable, map } from "rxjs";
import { Service } from "typedi";
import { IPayPalTokenSource } from "../models/IPayPalTokenSource";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { PayPalTokenSourceToken } from "../../../client/dependency-injection/InjectionTokens";

/**
 * Provides a stream of order IDs for PayPal which are obtained via the message bus. It's expected
 * that there will be other code that publishes the data via the message bus for this class to pick it up.
 */
@Service({ id: PayPalTokenSourceToken, multiple: true })
export class MessageBusPayPalTokenSource implements IPayPalTokenSource {
  constructor(private readonly messageBus: IMessageBus) {}

  tokenStream(): Observable<string> {
    return this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.PAYPAL_CREATE_ORDER_RESPONSE),
      map((event: IMessageBusEvent<any>) => {
        // Invalid will always return string. Empty string will be rejected by PayPal.
        return event.data ?? "";
      }),
    );
  }
}
