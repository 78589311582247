const HTML_CONTENT = `
  <style>
    .title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .order-summary {
      text-align: left;
      margin-bottom: 20px;
    }
    .order-summary h2 {
      font-size: 20px;
      margin: 0 0 10px 0;
    }
    .order-summary p {
      margin: 5px 0;
    }
    button {
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      margin: 10px 0;
      cursor: pointer;
      font-size: 16px;
      width: calc(100% - 20px); /* Adjust width for padding */
    }
    button:hover {
      opacity: 0.8;
    }
    #cancel {
      background-color: #6185ab; /* Yellow for cancel to be less alarming */
    }
    #error {
      background-color: #ff3b30; /* Red for error to indicate caution or stop */
    }
    #authorize {
      background-color: #007aff; /* Keep the authorize button blue */
    }
    .error-message {
      color: #ff3b30;
      display: none; /* Initially hidden */
    }
  </style>
  <div class="title">Payment</div>
  <div class="order-summary">
    <h2>Order Summary</h2>
    <p>Item: Fancy Widget</p>
    <p>Price: £99.99</p>
    <p>Shipping: Free</p>
    <p>Total: £99.99</p>
  </div>

  <button id="error">Error</button>
  <button id="cancel">Cancel</button>
  <button id="authorize" disabled>Authorize</button>
`;

export class ApplePayMockModal {
  private overlay: HTMLElement;
  private modalContent: HTMLElement;

  private onAuthorize: () => void = () => {};
  private onError: () => void = () => {};
  private onCancel: () => void = () => {};

  constructor() {
    this.overlay = document.createElement("div");
    this.modalContent = document.createElement("div");

    // Set IDs for easier CSS targeting and JS manipulation
    this.overlay.id = "applePayMockOverlay";
    this.modalContent.id = "applePayMockModal";

    this.setupOverlay();
    this.setupModal();
  }

  public setAuthorizeCallback(callback: () => void): void {
    this.onAuthorize = callback;
  }

  public setErrorCallback(callback: () => void): void {
    this.onError = callback;
  }

  public setCancelCallback(callback: () => void): void {
    this.onCancel = callback;
  }

  private setupOverlay(): void {
    Object.assign(this.overlay.style, {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    });
  }

  private setupModal(): void {
    Object.assign(this.modalContent.style, {
      maxWidth: "400px",
      minHeight: "600px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    });
  }

  public open(): void {
    this.modalContent.innerHTML = HTML_CONTENT;
    this.overlay.appendChild(this.modalContent);
    document.body.appendChild(this.overlay);
    this.attachEventListeners();
  }

  private attachEventListeners(): void {
    this.modalContent
      .querySelector("#cancel")
      ?.addEventListener("click", () => {
        this.close();
        this.onCancel();
      });

    this.modalContent.querySelector("#error")?.addEventListener("click", () => {
      this.close();
      this.onError();
    });

    this.modalContent
      .querySelector("#authorize")
      ?.addEventListener("click", () => {
        this.close();
        this.onAuthorize();
      });
  }

  public close(): void {
    this.overlay.remove();
  }

  public unlock(): void {
    const authorizeButton = this.modalContent.querySelector(
      "#authorize",
    ) as HTMLButtonElement;
    if (authorizeButton) authorizeButton.disabled = false;
  }
}
