import { jwtDecode } from "jwt-decode";
import { Service } from "typedi";
import { IStJwtObj } from "../../../application/core/models/IStJwtObj";
import { InternalsMonitor } from "../../../application/core/services/monitoring/InternalsMonitor";
import { ConfigProvider } from "../config-provider/ConfigProvider";

@Service()
export class JwtDecoder {
  constructor(protected configProvider: ConfigProvider) {}

  decode<T>(jwt: string): IStJwtObj<T> {
    if (!jwt) {
      //The JWT could not be decoded: {error.message}
      const error = new Error("Invalid 'JWT', undefined or empty string.");
      // Using static, because JwtDecoder is loaded before the container registration
      InternalsMonitor.getInstance().recordIssue(
        error,
        this.configProvider.getConfig(),
      );
      throw error;
    }

    try {
      return jwtDecode<IStJwtObj<T>>(jwt);
    } catch (e) {
      // Using static, because JwtDecoder is loaded before the container registration
      InternalsMonitor.getInstance().recordIssue(
        new Error(`The 'JWT' could not be decoded: ${e.message}`),
        this.configProvider.getConfig(),
      );
      throw new Error(`Invalid 'JWT', cannot parse: ${jwt}.`);
    }
  }
}
