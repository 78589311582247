import { Inject, Service } from "typedi";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { Observable, catchError, throwError } from "rxjs";
import { Money } from "ts-money";
import { ConfigProvider } from "../../../shared/services/config-provider/ConfigProvider";
import { IConfig } from "../../../shared/model/config/IConfig";
import { JwtDecoder } from "../../../shared/services/jwt-decoder/JwtDecoder";
import { IGooglePayTransactionInfo } from "../../../integrations/google-pay/models/IGooglePayTransactionInfo";
import { IStJwtPayload } from "../../../application/core/models/IStJwtPayload";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { IGooglePaySessionPaymentsClient } from "../../../integrations/google-pay/models/IGooglePaySessionPaymentsClient";
import { IUpdateJwt } from "../../../application/core/models/IUpdateJwt";
import { SentryService } from "../../../shared/services/sentry/SentryService";
import { type IInternalsMonitor } from "../../../application/core/services/monitoring/IInternalsMonitor";
import { IGooglePaySdkProvider } from "./google-pay-sdk-provider/IGooglePaySdkProvider";
import { GooglePayButton } from "./GooglePayButton";
import { GooglePayButtonDomInjector } from "./GooglePayButtonDomInjector";

@Service()
export class GooglePayClient {
  private config: IConfig;
  private destroy$: Observable<IMessageBusEvent>;

  constructor(
    private configProvider: ConfigProvider,
    private jwtDecoder: JwtDecoder,
    private messageBus: IMessageBus,
    private googlePaySdkProvider: IGooglePaySdkProvider,
    private sentryService: SentryService,
    private googlePayButton: GooglePayButton,
    private googlePayButtonDomInjector: GooglePayButtonDomInjector,
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
  ) {
    this.destroy$ = this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY));
  }

  init(config: IConfig): Observable<IConfig> {
    this.config = config;

    return this.googlePaySdkProvider
      .setupSdk$(this.configProvider.getConfig())
      .pipe(
        this.sentryService.captureAndReportResourceLoadingTimeout(
          "Google Pay script load timeout",
        ),
        catchError((error) => {
          //this.internalMonitor.recordIssue(error);
          return throwError(() => error);
        }),
        tap((googlePaySdk: IGooglePaySessionPaymentsClient) => {
          const button = this.googlePayButton.createButton(
            config,
            googlePaySdk,
          );
          const { buttonRootNode } = config.googlePay.buttonOptions;
          this.googlePayButtonDomInjector.addButtonToDom(
            buttonRootNode,
            button,
          );
        }),
        switchMap(() => this.configProvider.getConfig$()),
        tap((config: IConfig) => {
          this.config = config;
          this.updateJwtListener();
        }),
      );
  }

  private updateConfigWithJWT(jwt: string): IConfig {
    this.config.jwt = jwt;
    const { payload }: { payload: IStJwtPayload } = this.jwtDecoder.decode(jwt);

    let totalPrice = payload.mainamount;

    if (totalPrice === undefined) {
      totalPrice = Money.fromInteger({
        amount: parseInt(payload.baseamount, 10),
        currency: payload.currencyiso3a,
      }).toString();
    }

    const transactionInfo: IGooglePayTransactionInfo = {
      ...this.config.googlePay.paymentRequest.transactionInfo,
      currencyCode: payload.currencyiso3a,
      totalPrice,
    };

    return {
      ...this.config,
      googlePay: {
        ...this.config.googlePay,
        paymentRequest: {
          ...this.config.googlePay.paymentRequest,
          transactionInfo,
        },
      },
    };
  }

  private updateJwtListener(): void {
    this.messageBus
      .pipe(
        ofType(PUBLIC_EVENTS.UPDATE_JWT),
        tap((event: IMessageBusEvent<IUpdateJwt>) => {
          this.updateConfigWithJWT(event.data.newJwt);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
