import { Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../../../application/core/models/constants/EventTypes";
import { CONTROL_FRAME_IFRAME } from "../../../../../application/core/models/constants/Selectors";
import { IMessageBusEvent } from "../../../../../application/core/models/IMessageBusEvent";
import { IFrameQueryingService } from "../../../../../shared/services/message-bus/interfaces/IFrameQueryingService";
import { IApplePayValidateMerchantRequest } from "../../models/apple-pay-walletverify-data/IApplePayValidateMerchantRequest";
import { IApplePayWalletVerifyResponseBody } from "../../models/apple-pay-walletverify-data/IApplePayWalletVerifyResponseBody";
import { IApplePaySession } from "../../models/IApplePaySession";

import { IApplePayValidateMerchantEvent } from "../../models/IApplePayValidateMerchantEvent";

@Service()
export class ApplePayMerchantValidationAction {
  constructor(private frameQueryingService: IFrameQueryingService) {}

  execute(
    applePaySession: IApplePaySession,
    validateMerchantRequest: IApplePayValidateMerchantRequest,
    event: IApplePayValidateMerchantEvent,
  ): void {
    const validateMerchantQueryEvent: IMessageBusEvent<IApplePayValidateMerchantRequest> =
      {
        type: PUBLIC_EVENTS.APPLE_PAY_VALIDATE_MERCHANT_2,
        data: {
          ...validateMerchantRequest,
          walletvalidationurl: event.validationURL,
        },
      };

    this.frameQueryingService
      .query(validateMerchantQueryEvent, CONTROL_FRAME_IFRAME)
      .subscribe({
        next: (response: IApplePayWalletVerifyResponseBody) => {
          if (Number(response.errorcode) === 0) {
            applePaySession.completeMerchantValidation(
              JSON.parse(response.walletsession),
            );
          } else {
            applePaySession.abort();
          }
        },
        error: () => {
          applePaySession.abort();
        },
      });
  }
}
