import { ContainerInstance, Service } from "typedi";
import { first } from "rxjs/operators";
import {
  MessageBusToken,
  MessageSubscriberToken,
  StoreToken,
  TranslatorToken,
} from "../../shared/dependency-injection/InjectionTokens";

import { BrowserLocalStorage } from "../../shared/services/storage/BrowserLocalStorage";
import { FrameIdentifier } from "../../shared/services/message-bus/FrameIdentifier";
import { FramesHub } from "../../shared/services/message-bus/FramesHub";
import { IConfig } from "../../shared/model/config/IConfig";
import { IMessageBus } from "../../application/core/shared/message-bus/IMessageBus";
import { InterFrameCommunicator } from "../../shared/services/message-bus/InterFrameCommunicator";
import { InternalsMonitor } from "../../application/core/services/monitoring/InternalsMonitor";
import { MERCHANT_PARENT_FRAME } from "../../application/core/models/constants/Selectors";
import { MessageSubscriberRegistry } from "../../shared/services/message-bus/MessageSubscriberRegistry";
import { PUBLIC_EVENTS } from "../../application/core/models/constants/EventTypes";
import { ST } from "../st/ST";
import { SentryService } from "../../shared/services/sentry/SentryService";
import { environment } from "../../environments/environment";
import { ofType } from "../../shared/services/message-bus/operators/ofType";
import { NonControlFrameInternalsMonitorFactory } from "../../application/core/services/monitoring/NonControlFrameInternalsMonitorFactory";

@Service()
export class ClientBootstrap {
  private isAlreadyRunning = false;

  constructor(
    private frameIdentifier: FrameIdentifier,
    private container: ContainerInstance,
  ) {}

  run(config: IConfig): ST {
    if (this.isAlreadyRunning) {
      // REVIEW: This is never executed. Always false.
      // InternalsMonitor.getInstance check works.
      this.container.get(ST).destroy();
      console.warn(
        "The current instance of ST has been destroyed as a result of starting ST again",
      );
    }

    this.frameIdentifier.setFrameName(MERCHANT_PARENT_FRAME);
    const isFirstMonitorSetup = !InternalsMonitor.hasInstance();

    if (isFirstMonitorSetup) {
      // Configure the internal monitor for the component.
      InternalsMonitor.setInstance(
        this.container
          .get(NonControlFrameInternalsMonitorFactory)
          .create(this.frameIdentifier),
      );

      this.container.set("IInternalsMonitor", InternalsMonitor.getInstance());

      window.addEventListener("error", (error: ErrorEvent) => {
        // TODO: Review if we can enable this. Currently we cannot as it would capture
        // any error on the webpage. Note that Sentry already does this.
        // InternalsMonitor.getInstance().recordIssue(error.error);
      });
    }

    this.isAlreadyRunning = true;

    this.container
      .get(SentryService)
      .init(environment.SENTRY.DSN, environment.SENTRY.ALLOWED_URLS, true);
    this.container.get(InterFrameCommunicator).init();
    this.container.get(FramesHub).init();
    this.container.get(MessageBusToken);
    this.container.get(StoreToken);
    this.container.get(BrowserLocalStorage).init();
    this.container
      .get(MessageSubscriberRegistry)
      .register(...this.container.getMany(MessageSubscriberToken));
    this.container.get(TranslatorToken).init();

    const st: ST = this.container.get(ST);
    const messageBus: IMessageBus = this.container.get(MessageBusToken);

    st.init(config);

    messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY), first()).subscribe(() => {
      this.isAlreadyRunning = false;
    });

    return st;
  }
}
