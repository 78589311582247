import { IConfig } from "../../../../shared/model/config/IConfig";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { InterFrameCommunicator } from "../../../../shared/services/message-bus/InterFrameCommunicator";
import { type IInternalsMonitor } from "./IInternalsMonitor";
import { InternalsMonitorUtil } from "./InternalsMonitorUtil";

/**
 * The secondary implementatation of the internals monitor which does not
 * communicate directly with DataDog like DataDogAppMonitor does.
 *
 * The purpose of this implementation is to be provided to the non-parent
 * components so that errors can be shared with the parent, where the messages
 * will be captured and passed along to DataDog.
 */
export class InternalsMonitorBroadcaster implements IInternalsMonitor {
  constructor(private interframeCommunicator: InterFrameCommunicator) {}

  /**
   * Does nothing.
   * @param config Not used.
   */
  initialize(config: IConfig): void {
    throw new Error(
      "initialize was called, but this should never happen on this instance",
    );
  }

  /**
   * Records the issue and broadcasts it to the relevant frame.
   * @param error The error to record
   * @param context The error context
   */
  recordIssue(error: Error, context?: object): void {
    this.interframeCommunicator.sendToControlFrame({
      type: PUBLIC_EVENTS.APP_ERROR,
      data: InternalsMonitorUtil.constructErrorMessage(error, context || {}),
    });
  }

  recordActivity(type: string, context?: object): void {
    this.interframeCommunicator.sendToControlFrame({
      type: PUBLIC_EVENTS.APP_ACTION_TRACKING,
      data: { type, context },
    });
  }
}
