import { Inject, Service } from "typedi";
import { IApplePaySession } from "../../models/IApplePaySession";
import { IApplePayConfigObject } from "../config/IApplePayConfigObject";
import { IApplePayNamespace } from "../../models/IApplePayNamespace";
import { type IApplePayEventBinder } from "../payment/IApplePayEventBinder";
import { ApplePayApplePayEventBinderToken } from "../../../../../client/dependency-injection/InjectionTokens";

@Service()
export class ApplePaySessionManager {
  /**
   * This manager lets us consume any implementation of the ApplePay Namespace, allowing
   * us to use either official ApplePay implementations or use mocks.
   */
  constructor(
    private applePayNamespace: IApplePayNamespace,
    @Inject(ApplePayApplePayEventBinderToken)
    private actionBinder: IApplePayEventBinder,
  ) {}

  /**
   * Launches a new apple-pay session. This means that a session is created
   * and a modal will appear to take payment.
   * @param config
   *  The configuration for the ApplePay payment.
   *
   * @returns
   *  The session that was started.
   */
  public launch(config: IApplePayConfigObject): IApplePaySession {
    // Start the applepay instance
    const applePaySession = this.applePayNamespace.createInstance(
      config.applePayVersion,
      config.paymentRequest,
    );

    this.actionBinder.bindAll(applePaySession, config);
    applePaySession.begin();
    return applePaySession;
  }
}
